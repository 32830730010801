import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { AnalyticService } from 'src/app/services/analytic-service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { CommonService } from 'src/app/services/common.service';
import { ContactService } from 'src/app/services/contact.service';
import { PixelService } from 'src/app/services/pixel.service';
import { SupplierRegistrationService } from 'src/app/services/supplier-registration.service';
import { FieldConfig } from 'src/app/shared/component/field.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  text: string;
  firstFormGroup: UntypedFormGroup;
  @Input() input: any;
  @Input() data: any;
  @Input() item: any;

  type: any;

  customField31: any;

  regConfig: any[] = [

  ];
  init: boolean = false;
  gaId: any;
  formGroupId: any;
  customField13: any;
  customField8: any;
  customField5: any;
  customField4: any;
  customField28: any = 'Form Submitted SuccessFully';
  customField29: any = 'Form Submitted Failed';
  customField10: any = '/home';
  layout: number;
  calculatedResult: number;

  constructor(private _formBuilder: UntypedFormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    public googleAnalyticsService: AnalyticService,
    private contactService: ContactService,
    private pixelService: PixelService,
    private checkoutService: CheckoutService,
    private supplierRegistrationService: SupplierRegistrationService,) { }

  alignMent: any = 2;
  fieldData = [
    { label: 'Name', controlName: 'name', type: 'text' },
    { label: 'Telephone', controlName: 'telephone', type: 'text' },
    { label: 'Subject', controlName: 'subject', type: 'textarea' },
    { label: 'Email Id', controlName: 'emailId', type: 'text' },
    { label: 'Message', controlName: 'message', type: 'textarea' },
  ];

  getFormFields(fieldCount: number, fields: any[]): any[] {
    const result = [];
    for (let i = 0; i < fields.length; i++) {
      if (i % fieldCount === 0) {
        result.push(fields[i]);
      } else {
        result.splice(i % fieldCount, 0, fields[i]);
      }
    }
    return result;
  }
  getMobileFlexValue(customField27: any = 1): string {

    return customField27 === 2 ? '50%' : customField27 === 3 ? '33.33%' : '100%';
  }

  getFlexValue(columns: number): string {

    const columnCount = columns ?? 1;

    switch (columnCount) {
      case 2: return '50%'; // Two fields per row
      case 3: return '33.33%'; // Three fields per row
      case 4: return '25%'; // Four fields per row
      default: return '100%'; // One field per row
    }
  }

  ngOnInit(): void {


    console.log(this.customField4);
    

    if (window.innerWidth > 600) {
      this.layout = this.item.customField26 ? parseInt(this.item.customField26) : 2; // Default to 2 if customField26 is empty
    } else {
      this.layout = this.item.customField27 ? parseInt(this.item.customField27) : 1; // Default to 1 for mobile views
    }

    if (window.innerWidth > 600) {


      if (this.item.customField26 != '') {
        this.alignMent = this.item.customField26
      }




    } else {
      if (this.item.customField27 != '') {
        this.alignMent = this.item.customField27
      }
    }



    if (this.item['customField30'] != undefined) {
      this.type = parseInt(this.item['customField30'])


    } else {
      this.type = 0;

    }


    if (this.item.customField31 != undefined) {
      this.customField31 = this.item.customField31;
    }

    if (this.item.customField13 != undefined) {
      this.customField13 = this.item.customField13;
    }

    if (this.item.customField5 != undefined) {
      this.customField5 = this.item.customField5;
    }

    if (this.item.customField4 != undefined) {
      this.customField4 = this.item.customField4;
    }


    if (this.item.customField8 != undefined) {
      this.customField8 = this.item.customField8;
    }

    if (this.item.customField28 != undefined) {
      this.customField28 = this.item.customField28;
    }

    if (this.item.customField29 != undefined) {
      this.customField29 = this.item.customField29;
    }

    if (this.item.customField10 != undefined) {
      this.customField10 = this.item.customField10;
    }


    if (this.type == 0) {
      this.createContactForm();
    } else {
      this.formGroupId = this.item['value']
      if(this.formGroupId !=null)
        {
          this.createDynamicForm(this.formGroupId);
        }else{
          console.log("Form Not Selected");
          
        }
      
    }

    this.text = 'Submit';
  }

  createContactForm() {
    this.firstFormGroup = this._formBuilder.group({
      id: [''],
      name: ['', [Validators.required]],
      telephone: ['', [Validators.required]],
      emailId: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
      isActive: new UntypedFormControl(0)
    });
  }


  createDynamicForm(formGroupId) {
    this.contactService.getDynmaicForm(formGroupId).subscribe((data) => {

      let value: any = data
      this.regConfig = value;
      this.gaId = this.regConfig[0].gaTrackingId;
      this.formGroupId = formGroupId;

      this.init = true;
    });
  }

  createContact() {

    this.firstFormGroup.value.operationType = "New"

    if (this.firstFormGroup.valid && this.text == 'Submit') {
      this.text = "Processing"
      let uniqueId: any = this.generateUniqueId();
      this.setConversionTrackingId();
      this.setFacebookConverison(uniqueId, 1);
      this.contactService.createContact(this.firstFormGroup.value).subscribe(
        (response) => {
          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
            this.snackBar.open(this.customField28, '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
            this.text = "Submit";
            this.firstFormGroup.reset();
          } else {
            this.text = "Submit";

            this.snackBar.open(this.customField29, '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
        })
    } else {
      this.firstFormGroup.markAllAsTouched();
    }

  }




  setConversionTrackingId() {

    let grandTotal: any = '1.0';
    let value: any = localStorage.getItem('currency');
    this.googleAnalyticsService.event('purchase', grandTotal);
    if (this.gaId != null) {

      this.googleAnalyticsService.eventEmitter
        ('conversion', this.gaId,
          grandTotal,
          value, this.formGroupId);
    }
    else if (this.data['coversionTrackingIdGa'] != null) {
      this.googleAnalyticsService.eventEmitter
        ('conversion', this.data['coversionTrackingIdGa'],
          grandTotal,
          value, this.formGroupId);
    }
  }

  setFacebookConverison(uniqueId, grandTotal) {
    let value: any = localStorage.getItem('currency');
    this.pixelService.track('Purchase', {
      content_ids: uniqueId,
      value: grandTotal,
      currency: value
    });
    this.pixelService.track('Lead', {
      content_ids: uniqueId,
      value: grandTotal,
      currency: value
    });
  }

  generateUniqueId(): string {
    // Generate a unique ID using Renderer2
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  }

  submit(value: any) {

    let currentURL = window.location.href;

    value.formId = this.item['value'];

    value.title = this.item['title'];

    value.currentURL = window.location.href;

    let uniqueId: any = this.generateUniqueId();
    value.uniqueFormId = uniqueId;

    let filteredList = this.regConfig
      .filter(item => item.inputType.toLowerCase().includes('button'))
      .map(item => ({ label: item.label }));


    filteredList.forEach(label => {
      if (value.hasOwnProperty(label.label)) {
        delete value[label.label];
      }
    });

    let filteredListFile = this.regConfig
      .filter(item => item.inputType.toLowerCase().includes('file'))
      .map(item => ({ label: item.name }));

    filteredListFile.forEach(label => {
      if (value.hasOwnProperty(label.label)) {
        const newKey = label.label + '_file';
        value[newKey] = value[label.label];
        delete value[label.label];
      }
    });

    let object = {};
    let keysToCheck = ["phoneNumber", "email", "name"];
    let keysList = keysToCheck.filter(key => value.hasOwnProperty(key));
    keysList.forEach(key => {
      object[key] = value[key];
    });

    object['formGroupId'] = this.item['value'];
    object['value'] = JSON.stringify(value);


    if (this.customField31 == 1) {
      this.saveForm(object);
  } else if (this.customField31 == 2) {
      this.Calculation(object);
  } else {
      this.SubmitVendor(object);
  }
  }

  saveForm(object) {
    if (this.text == 'Submit') {
      this.contactService.saveForm(object).subscribe(
        (response) => {

          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
            this.text = "Submit";

            this.customField28 = 'Form Submitted SuccessFully';

            this.snackBar.open(this.customField28, '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });

            if (this.gaId != null) {
              this.setConversionTrackingId();

              let value: any = localStorage.getItem('currency');
              this.pixelService.track('Purchase', {
                content_ids: this.formGroupId,
                value: 1,
                currency: value
              });
              this.pixelService.track('Lead', {
                content_ids: this.formGroupId,
                value: 1,
                currency: value
              });
            }

            this.router.navigate([this.customField10]);
          }
          else {
            this.text = "Submit";
            this.snackBar.open(this.customField29, '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
        },
        (err) => { this.text = "Submit" });
    }
    else {

    }
  }


  SubmitVendor(object) {

    if (this.text == 'Submit') {

      if (object.address == null) {
        object.address = 0;
      }

      if (object.gstNo == null) {
        object.gstNo = 0;
      }

      if (object.phoneNo == null) {
        object.phoneNo = 0;
      }

      if (object.pincode == null) {
        object.pincode = 0;
      }

      if (object.state == null) {
        object.state = 0;
      }

      if (object.city == null) {
        object.city = 0;
      }

      if (object.accountNo == null) {
        object.accountNo = 0;
      }

      if (object.confirmAccount == null) {
        object.confirmAccount = 0;
      }

      if (object.ifscCode == null) {
        object.ifscCode = 0;
      }

      if (object.storeName == null) {
        object.storeName = 0;
      }

      if (object.country == null) {
        object.country = 'India';
      }

      if (object.email == null) {
        object.email = 0;
      }

      if (object.password == null) {
        object.password = 0;
      }

      if (object.operationType = "New") {
        this.supplierRegistrationService.submitSupplier(object).subscribe(
          (response) => {
            if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
              this.text = "Submit";

              this.snackBar.open(this.customField28, '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
              this.router.navigate([this.customField10]);
            }
            else {
              this.text = "Submit";
              this.snackBar.open(this.customField29, '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
            }
          },
          (err) => { this.text = "Submit" });
      }
      else {

      }
    }


  }

  Calculation(object) {

    const parsedValue = JSON.parse(object.value);

    const calculatedResult = parsedValue.calculatedResult;

    console.log('Calculated Result:', calculatedResult);
    console.log('Parsed Object:', parsedValue);
    
  }




}
